import './style.css'
import * as THREE from 'three'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'

/**
 * Base
 */

// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()

/**
 * Textures
 */


/**
 * Models
 */
//const gltfLoader = new GLTFLoader()

let mixer = null

const updateMaterial = () => {
    scene.traverse((child) => {
        if(child instanceof THREE.Mesh) {
            child.castShadow = true;
            child.receiveShadow = true;
            
            if(child.material.map) {
                child.material.map.anisotropy = 16;
            }
        }
    });
}

const loadingManager = new THREE.LoadingManager()
const loader = new GLTFLoader(loadingManager)
const loadingbar = document.getElementById('progress')

loader.load(
    '/models/computer.glb',
    // on load handler
    async (gltf) => {
        gltf.scene.scale.set(2.5, 2.5, 2.5)
        scene.add(gltf.scene)
        updateMaterial()
        setMaterialsOnGLTF(gltf.scene)
    },
    // on progress handler
    (xhr) => {
        const progress = Math.round(((xhr.loaded / 10782528 + 1) / 2) * 100);

        if(progress >= 100){
            if(loadingbar) loadingbar.remove();
        } else {
            loadingbar.style.width = progress + '%';
        }
    }
)



/*gltfLoader.load(
    '/models/computer.glb',
    (gltf) => {
        gltf.scene.scale.set(2.5, 2.5, 2.5)
        scene.add(gltf.scene)
        updateMaterial()
        setMaterialsOnGLTF(gltf.scene)
    }
)*/

function setMaterialsOnGLTF(object3D){
    if (object3D.material) {
        const newMaterial = new THREE.MeshNormalMaterial();
        newMaterial.roughness = 0.75;
        newMaterial.wireframe = true;

        object3D.material = newMaterial;
    }
    if (!object3D.children) {
        return;
    }
    for (let i = 0; i < object3D.children.length; i++) {
        setMaterialsOnGLTF(object3D.children[i]);
    }

  }

/**
 * Lights
 */
const ambientLight = new THREE.AmbientLight(0xececec, 0.7)
//scene.add(ambientLight)

const directionalLight = new THREE.DirectionalLight(0xffffff, 0.5)
directionalLight.castShadow = true
directionalLight.shadow.mapSize.set(2048, 2048)
directionalLight.shadow.camera.near = 1
directionalLight.shadow.camera.far = 8
directionalLight.shadow.camera.left = - 7
directionalLight.shadow.camera.top = 7
directionalLight.shadow.camera.right = 7
directionalLight.shadow.camera.bottom = - 7
directionalLight.shadow.bias = - 0.01
directionalLight.position.set(0, 6, 5)
// scene.add(directionalLight)

const directionalLight2 = new THREE.DirectionalLight(0xffffff, 0.3)
directionalLight2.castShadow = true
directionalLight2.shadow.mapSize.set(2048, 2048)
directionalLight2.shadow.camera.near = 1
directionalLight2.shadow.camera.far = 8
directionalLight2.shadow.camera.left = - 5
directionalLight2.shadow.camera.top = 5
directionalLight2.shadow.camera.right = 5
directionalLight2.shadow.camera.bottom = - 5
directionalLight2.shadow.bias = - 0.01
directionalLight2.position.set(0, 1, -5)
// scene.add(directionalLight2)

const directionalLight3 = new THREE.DirectionalLight(0xcccccc, 0.5)
directionalLight3.castShadow = true
directionalLight3.shadow.mapSize.set(2048, 2048)
directionalLight3.shadow.camera.near = 1
directionalLight3.shadow.camera.far = 8
directionalLight3.shadow.camera.left = - 5
directionalLight3.shadow.camera.top = 5
directionalLight3.shadow.camera.right = 5
directionalLight3.shadow.camera.bottom = - 5
directionalLight3.shadow.bias = - 0.01
directionalLight3.position.set(0, -1, 2)
// scene.add(directionalLight3)

/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

    // Update animation variables
    windowX = window.innerWidth / 2;
    windowY = window.innerHeight / 2;
})

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 100)
camera.position.set(0, .5, 9)
scene.add(camera)

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    antialias: true,
    alpha: true
})
renderer.shadowMap.enabled = true
renderer.shadowMap.type = THREE.PCFSoftShadowMap
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

/**
 * Animate
 */
let mouseX = 0
let mouseY = 0
let targetX = 0
let targetY = 0

let windowX = window.innerWidth / 2;
let windowY = window.innerHeight / 2;

document.addEventListener('mousemove', function(event){
    mouseX = (event.clientX - windowX)
    mouseY = (event.clientY - windowY)
})

const clock = new THREE.Clock()
let previousTime = 0
const tick = () => {
    targetX = mouseX * .0005
    targetY = mouseY * .00095

    const elapsedTime = clock.getElapsedTime()
    const deltaTime = elapsedTime - previousTime
    previousTime = elapsedTime

    // Update objects
    scene.traverse((child) => {
        if (child instanceof THREE.Mesh){
            child.rotation.y += .065 * (targetX - child.rotation.y)
            child.rotation.x += .065 * (targetY - child.rotation.x)
        }
    });

    // Render
    renderer.render(scene, camera)

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick()